<template>
	<AppLayout>
		<template #navigation>
			<HeaderNavigationTabs
				v-if="!activityStore.loading && activityStore.activityLoaded"
				:tabs="[ActivityStatus.ARCHIVED, ActivityStatus.OPEN, ActivityStatus.CLOSED, ActivityStatus.RESOLVED].includes(activityStore.activityStatus) ? tabs : []"
			/>
		</template>
		<template #header>
			<PageHeader
				v-if="!activityStore.loading && activityStore.activityLoaded"
				:tags="getActivityTags"
				:title="ucword(route.params.activity_type.toString()) + ' #' + route.params.activity_id"
				:show-breadcrumbs="true"
				:base-breadcrumb="baseBreadcrumb"
				:breadcrumbs="breadcrumbs()"
			></PageHeader>
		</template>
		<template #primary>
			<div v-if="!activityStore.loading && activityStore.activityLoaded" class="flex flex-col h-full w-full">
				<div v-if="activityStore.activityStatus === ActivityStatus.CREATING" class="w-full h-full">
					<FormRequest :form-request-id="activityStore.activity.initiator_form_request_id ?? ''" :title="ucword(route.params.activity_type.toString()) + ' initiator'" reference-name="Initiator" submit-button-label="Complete"></FormRequest>
				</div>
				<router-view v-else />
			</div>
			<div v-else class="flex flex-row justify-center items-center h-full"><Loading /></div>
		</template>
	</AppLayout>
</template>

<script lang="ts" setup>
	import AppLayout from '@layouts/AppLayout.vue';
	import { BreadcrumbObject, HeaderNavigationTab, PageHeaderTag } from '@/types/layout';
	import HeaderNavigationTabs from '@components/HeaderNavigationTabs.vue';
	import { faCalendar, faClock, faGear, faUser, faGauge } from '@fortawesome/pro-solid-svg-icons';
	import { ucword } from '@utils/helpers';
	import { useRoute } from 'vue-router';
	import PageHeader from '@components/PageHeader.vue';
	import { useActivityStore } from '@modules/activities/store';
	import { computed, onMounted, onUnmounted } from 'vue';
	import Loading from '@components/Loading.vue';
	import plur from '@utils/usePlural';
	import { ActivityStatus } from '@/types/activity';
	import FormRequest from '@modules/form/components/FormRequest.vue';

	const route = useRoute();
	const activityStore = useActivityStore();

	const baseBreadcrumb = {
		route: { name: 'activity.index', params: { activity_type: route.params.activity_type.toString() } },
		label: ucword(plur(route.params.activity_type.toString(), 2)),
	} as BreadcrumbObject;

	const breadcrumbs = () => {
		const breadcrumbs = [];
		breadcrumbs.push({
			route: { name: 'activity.details', params: { activity_type: route.params.activity_type.toString(), activity_id: route.params.activity_id } },
			label: ucword(route.params.activity_type.toString()) + ' #' + route.params.activity_id,
		});
		for (const matchedRoute of route.matched) {
			if (matchedRoute.name == 'activity.interactions') {
				breadcrumbs.push({
					route: {
						name: matchedRoute.name,
						params: { activity_type: route.params.activity_type.toString(), activity_id: route.params.activity_id },
					},
					label: activityStore.interactionLabelPlural,
				});
			} else {
				breadcrumbs.push({
					route: {
						name: matchedRoute.name,
						params: { activity_type: route.params.activity_type.toString(), activity_id: route.params.activity_id },
					},
					label: matchedRoute.meta.title,
				});
			}
		}
		return (breadcrumbs as BreadcrumbObject[]).filter((element) => {
			return element.route.name;
		});
	};

	const tabs = computed<HeaderNavigationTab[]>(() => {
		return [
			{ id: 1, name: 'Details', to: { name: 'activity.details' }, selected: true },
			{ id: 2, name: activityStore.interactionLabelPlural, to: { name: 'activity.interactions' }, selected: false },
			{ id: 3, name: 'Workflows', to: { name: 'activity.workflow' }, selected: false },
			{ id: 4, name: 'Forms', to: { name: 'activity.form' }, selected: false },
			{ id: 5, name: 'Variables', to: { name: 'activity.variables' }, selected: false },
			{ id: 6, name: 'Related', to: { name: 'activity.related' }, selected: false },
			{ id: 7, name: 'Log', to: { name: 'activity.log' }, selected: false },
		];
	});

	const getActivityTags = computed<PageHeaderTag[]>(() => {
		return [
			{
				id: 1,
				label: activityStore.activityCreator,
				icon: faUser,
			},
			{
				id: 2,
				label: activityStore.activityCreated,
				icon: faCalendar,
			},
			{
				id: 3,
				label: activityStore.activityLastUpdated,
				icon: faClock,
			},
			{
				id: 4,
				label: activityStore.activityType,
				icon: faGear,
			},
			{
				id: 5,
				label: activityStore.activityStatusLabel,
				icon: faGauge,
			},
		];
	});

	onMounted(async () => {
		await activityStore.getActivityById(route.params.activity_id.toString());
	});

	onUnmounted(async () => {
		await activityStore.clearActiveActivity();
	});
</script>
